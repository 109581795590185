<template>
  <div class="flex flex-col h-screen">
    <NavbarComponent/>
    <main class="mt-5">
      <section class="h-screen mt-3 bg-fortnox">
        <div class="w-full lg:w-4/6 mx-auto">
          <div class="pt-36">
            <div>
              <h3 class="text-white text-4xl lg:text-6xl text-center">FORTNOX & GOLFIGO</h3>
              <h5 class="text-yellow-500 text-center">En vinnande kombination.</h5>
            </div>
            <div class="pt-12 ">
              <p class="text-white text-2xl text-center">Grundarna av Golfigo är en av Fortnoxs ledande
                integrationspartners och har
                sedan 2019 levererat över 50
                andra kopplingar som hjälper företagare och redovisningsbyråer med effektivisering och
                automatisering.</p>
            </div>
            <div class="pt-12 mx-auto flex justify-center px-1 lg:px-0 ">
              <div class="grid grid-cols-1 gaps-2">
                <div class="flex" v-for="(item,i) in features" :key="key">
                  <div class="mr-2">
                    <svg width="20" height="20" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M53.3333 0H6.66667C2.96667 0 0 3 0 6.66667V53.3333C0 57 2.96667 60 6.66667 60H53.3333C57.0333 60 60 57 60 53.3333V6.66667C60 3 57.0333 0 53.3333 0ZM23.3333 46.6667L6.66667 30L11.3667 25.3L23.3333 37.2333L48.6333 11.9333L53.3333 16.6667L23.3333 46.6667Z"
                          fill="#78A55A"/>
                    </svg>

                  </div>
                  <div>
                    <p class="text-white text-xl">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="h-full w-full lg:w-4/6 mx-auto py-10 lg:py-20 px-[10px] lg:px-[0px]">
          <div class="w-full">
            <img src="./../../assets/mainImages/fortnox-integrationspartner2.png"
                 class="w-96 position-relative -left-8 lg:-left-23" alt="">
          </div>
          <div class="pt-12 lg:pt-16">
            <div class="">
              <p class="text-green-300 text-xl font-semibold">Småföretagarnas bästa vän.</p>
              <p class="text-black font-semibold pt-1 text-xl">
                Fortnox är ett av Sveriges mest använda ekonomiprogram med många
                funktioner som underlättar redovisning för små och stora bolag.
                Med Fortnox kan företagare sköta allt från bokföring till lagerhantering.
              </p>
            </div>
            <div>
              <div class="grid grid-cols-1 gaps-2">
                <ul class="list-disc">
                  <li v-for="(item,key) in benefits" :key="key" class="py-1"><span
                      class="text-xl font-semibold text-black">{{ item }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="h-full px-1 lg:px-0  flex justify-center bg-[#DEFCEA66] ">
          <div class="container py-20 gap-10 flex-col items-center flex justify-center">
            <p class="text-4xl lg:text-5xl text-center font-bold">
              Så här går det till
            </p>

            <div
                class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 py-10 gap-5 p-1 lg:p-5 items-center lg:p-0 mt-16 w-full">
              <div v-for="(data, index) in workFlow" :key="index"
                   class="bg-white  shadow-xl drop-shadow-xl cursor-pointer hover:-translate-y-10 transition ease-in-out duration-300 flex flex-col justify-around p-1 rounded-2xl group-hover:bg-[#56CCF2] hover:shadowxl hover:bg-[#56CCF2] hover:shadow-[#56CCF2] h-[300px]"
              >
                <div class="flex items-center gap-3 px-1 lg:px-3">
                  <div
                      class="bg-[#FBD531] font-bold  rounded-full text-white flex items-center justify-center p-1 w-[10px] h-[10px] lg:w-[16px] lg:h-[16px]">
                    {{ index + 1 }}
                  </div>
                  <p class="font-bold text-xl p-1  group-hover:text-white transition-colors text-[#293137]">
                    {{ data.title }}
                  </p>
                </div>

                <div class="py-1 lg:py-5 px-1 lg:px-3 w-full  flex justify-center">
                  <p class="font-normal text-[#3F3F3A] group-hover:text-white text-xl">
                    {{ data.description }}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </main>
    <FooterComponent/>
  </div>
</template>
<script>
import NavbarComponent from "./components/Navbar.vue";
import FooterComponent from "./components/Footer.vue";

export default {
  name: "Fortnox",
  components: {NavbarComponent, FooterComponent},
  data() {
    return {
      features: [
        "Exportera artiklar, medlemmar och fakturor från GIT till Fortnox",
        "Skicka kvitton till medlemmar/kunder som betalat sin faktura",
        "Skicka vänliga fakturapåminnelser till medlemmar/kunder som missat att betala sin faktura",
        "Schemalägg medlemskommunikation via SMS och/eller egen-designade nyhetsbrev",
        "Tillgång till lättförstådda och informativa finansiella grafer (kommer snart)"
      ],
      benefits: [
        "Helt molnbaserat",
        "Öppet API för skräddarsydda flöden",
        "Mer än 300 integrationer",
        "Populär bland byråer"
      ],
      workFlow: [
        {
          title: "Skapa ditt Golfigo-konto",
          description: "Registrera dig och din golfklubb som ny användare för att få tillgång till Golfigo-portalen."
        },
        {
          title: "Logga in och gör er konfiguration",
          description: "Vi vet att olika spelare har olika behov, därför har vi byggt Golfigo på ett sätt som gör det möjligt för varje golfklubb att konfigurera appen efter deras unika behov."
        },
        {
          title: "Njut av automatiseringen",
          description: "Använd Golfigo för automatiska medlems- & artikelsynkar, automatisk medlemsfakturering, automatiska utskick av kvitton- & fakturapåmminelser, schemalagda SMS- & nyhetsbrevsutskick och mycket mer."
        }
      ]
    };
  },

}
</script>
<!--<script setup lang="ts">-->
<!--const -->

<!--const -->


<!--const -->

<!--</script>-->
<style scoped>
.bg-fortnox {
  background: #06170D !important;
}

.-left-0 {
  left: -20px;
}
.tg-white{
  background: white;
}
</style>